// inspired from https://github.com/microsoft/monaco-editor/tree/main/samples/browser-esm-vite-react

import * as monaco from 'monaco-editor'
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker'
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker'

export function ensureMonacoWorkerInitialized(): void {
  if (monacoWorkerInitiliazed) return
  monacoWorkerInitiliazed = true

  initializeMonacoWorker()
}

let monacoWorkerInitiliazed = false

function initializeMonacoWorker() {
  self.MonacoEnvironment = {
    getWorker(_: unknown, label: string) {
      if (label === 'json') {
        return new jsonWorker()
      }
      return new editorWorker()
    },
  }

  monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true)
}
